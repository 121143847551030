export const colors = {
  primary: '#24A0ED',
  success: '#569D4F',
  lightSuccess: '#F0FDF4',
  darkDeem: '#666666',
  lightDeem: '#CCCCCC',
  black: '#000000',
  red: '#f03e3e',
  danger: '#AB3022',
  lightDanger: '#FDEBEB',
  light: '#F2F9FE',
  white: '#FFFFFF',
  gray: '#ebebeb',
  yellow: '#F7B801',
  // lightGray: '#EFEFEF',
  lightGray: '#F8F8F8',

  lightBlue: '#5462ff',
  softBlue: '#edf3ff',
  softGrey: '#868e96',
};
