"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AVAILABLE_BILLABLEMETRIC_CODE_ENUM = void 0;
var AVAILABLE_BILLABLEMETRIC_CODE_ENUM;
(function (AVAILABLE_BILLABLEMETRIC_CODE_ENUM) {
    AVAILABLE_BILLABLEMETRIC_CODE_ENUM["IMPORTED_ROWS"] = "IMPORTED_ROWS";
    AVAILABLE_BILLABLEMETRIC_CODE_ENUM["REMOVE_BRANDING"] = "REMOVE_BRANDING";
    AVAILABLE_BILLABLEMETRIC_CODE_ENUM["IMAGE_IMPORT"] = "IMAGE_IMPORT";
    AVAILABLE_BILLABLEMETRIC_CODE_ENUM["ADVANCED_VALIDATORS"] = "ADVANCED_VALIDATORS";
})(AVAILABLE_BILLABLEMETRIC_CODE_ENUM = exports.AVAILABLE_BILLABLEMETRIC_CODE_ENUM || (exports.AVAILABLE_BILLABLEMETRIC_CODE_ENUM = {}));
