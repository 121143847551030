"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationTypesEnum = exports.EventTypes = exports.ValidationTypes = exports.ColumnTypes = void 0;
exports.ColumnTypes = {
    STRING: 'String',
    NUMBER: 'Number',
    DATE: 'Date',
    EMAIL: 'Email',
    REGEX: 'Regex',
    SELECT: 'Select',
    ANY: 'Any',
    DOUBLE: 'Double',
    IMAGE: 'Image',
};
exports.ValidationTypes = {
    RANGE: 'range',
    LENGTH: 'length',
    UNIQUE_WITH: 'unique_with',
};
exports.EventTypes = {
    INIT_IFRAME: 'INIT_IFRAME',
    WIDGET_READY: 'WIDGET_READY',
    CLOSE_WIDGET: 'CLOSE_WIDGET',
    AUTHENTICATION_VALID: 'AUTHENTICATION_VALID',
    AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
    UPLOAD_STARTED: 'UPLOAD_STARTED',
    UPLOAD_TERMINATED: 'UPLOAD_TERMINATED',
    UPLOAD_COMPLETED: 'UPLOAD_COMPLETED',
    DATA_IMPORTED: 'DATA_IMPORTED',
    IMPORT_JOB_CREATED: 'IMPORT_JOB_CREATED',
};
var ValidationTypesEnum;
(function (ValidationTypesEnum) {
    ValidationTypesEnum["RANGE"] = "range";
    ValidationTypesEnum["LENGTH"] = "length";
    ValidationTypesEnum["UNIQUE_WITH"] = "unique_with";
})(ValidationTypesEnum = exports.ValidationTypesEnum || (exports.ValidationTypesEnum = {}));
